<template>
  <div class="my">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        label="发表时间"
        width="230">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.updateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="用户名" width="120"> </el-table-column>
      <el-table-column prop="title" label="文章名称" width="120"> </el-table-column>
      <el-table-column prop="auditContent" label="状态" width="100"></el-table-column>
      <el-table-column label="图片" width="150">
        <template slot-scope="scope">
          <img v-for="(item,index) in scope.row.imgs" :key="index" :src="item" alt="" width="40" height="40">
        </template>
      </el-table-column>
      <el-table-column prop="content" label="内容" width="520"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="page"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="total"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "BbjtwebsiteMy",

  data() {
    return {
      userInfo: {},
      tableData: [],
      currentPage: null,
      total: null,
      pageNum: 1,
      pageSize: 2,
    };
  },

  mounted() {
    let userMap = JSON.parse(localStorage.getItem("userMap"))
    if(userMap) {
      this.userInfo = userMap
      this.getPostList()
    }
  },

  methods: {
    handleClick(row) {
      console.log(row);
        this.$confirm('您确定要删除该篇文章吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delPost(row)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    async getPostList() {
      let data = {
        user_id: this.userInfo.id,
        page_num: this.pageNum,
        page_size: this.pageSize,
        service_type: localStorage.getItem("serviceType")*1
      }
      let postList = await this.api.post(this.apiPath.getPostList,data);
      this.tableData = postList.data.resultList
      this.total = postList.data.totalRecord
      this.currentPage = postList.data.pageNum
    },
    async delPost(row) {
      let data = {
        user_id: this.userInfo.id,
        id: row.id,
      }
      let res = await this.api.post(this.apiPath.delPostInfo,data);
      this.$message({
        type: 'success',
        message: res.msg
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getPostList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val
      this.getPostList()
    }
  },
};
</script>

<style lang="less" scoped>
.my {
  padding-top: 100px;
  width: 1150px;
  margin: auto;
  margin-bottom: 100px;
}
.page {
  margin-top: 20px;
}
@media screen and (max-width: 750px) {
  .my {
    width: 100%;
  }
}
</style>
<style>
.el-select-dropdown {
  top: 408px !important;
}
</style>