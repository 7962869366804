<template>
  <div class="detail">
    <div class="text">
      {{dataList.titles?JSON.parse(dataList.titles)[langtype]||dataList.title:dataList.title}}
      <!--      {{dataList.title}}-->
    </div>
    <div class="detail-item"
         v-for="item in dataList.items"
         :key="item.id">
      <div class="date"
           v-if="item.name">{{item.name}}</div>
      <div class="img"><img v-if="item.img"
             :src="item.img"
             alt=""></div>
      <div class="expansion"
           v-if="item.expansion">
        <div v-html="item.expansion"></div>
      </div>
      <div class="keyword"
           v-if="item.keyword">
        <div v-html="item.keyword"></div>
      </div>
    </div>
    <div id="details"></div>
    <div class="case clearfix">
      <div class="containercc">
        <ul class="caselist clearfix wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.1s">
          <li v-for="(item,index) in casesImg.items"
              @click="addCasesImg(item.id)"
              :key="index">
            <a v-if="index<4">
              <div class="box">
                <div class="pic"><img :src="item.cover_img"
                       class="middleCenter"
                       alt="" /></div>
                <div class="bt">{{item.titles?JSON.parse(item.titles)[langtype]||item.title:item.title}}</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'casesDetail',

  data () {
    return {
      dataList: [],
      casesImg: [],
      cases: [],
      langtype: 0
    };
  },

  created () {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
  },
  mounted () {
    this.addDataList(this.$route.query.id)
  },

  methods: {
    async addDataList (id) {
      // let res = await this.api.get(this.apiPath.general+'banner/'+`${id}`,{});
      // this.dataList = res
      // this.dataList.items.forEach(item=> {
      //   if(item.expansion) {
      //     // 位置遇到什么字符换行
      //     let arr = item.expansion.replace(/。|：|!|；/g, '。<br/>')
      //     item.expansion = arr
      //   }
      //   if(item.keyword) {
      //     let arr = item.keyword.replace(/。|：|!|；/g, '。<br/>')
      //     item.keyword = arr
      //   }
      // })
      let data = {
        layout_id: 28,
      }
      let casesImg = await this.api.post(this.apiPath.editor, data);
      this.casesImg = casesImg
      console.log(this.casesImg)
      let res = await this.api.get(this.apiPath.dynamicDetail + `${id}` + '/detail', {});
      this.dataList = res
      console.log(this.dataList)

      document.getElementById('details').innerHTML = res.detailses ? JSON.parse(res.detailses)[this.langtype] || res.details : res.details;
    },
    addCasesImg (id) {
      this.addDataList(id)
      document.documentElement.scrollTop = 0;
    }
  },
};
</script>

<style lang="less" scoped>
.detail {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  .text {
    font-size: 22px;
    color: #333;
    margin-bottom: 16px;
    text-align: center;
  }
  .date {
    font-size: 22px;
    color: #999;
    font-family: arial;
    margin-bottom: 36px;
    text-align: center;
  }
  .img {
    text-align: center;
  }
  .expansion {
    margin-top: 40px;
  }
  .keyword {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.inmain {
  .box {
    .span {
      font-size: 20px;
      color: #7f0114;
    }
    .bts {
      font-size: 35px;
      font-weight: bold;
      color: #333;
    }
    .fadeInUp {
      text-align: center;
    }
  }
}
.pic {
  img {
    width: 100%;
    height: 180px;
  }
}
@media screen and (max-width: 750px) {
  .detail {
    width: 100%;
    p {
      padding-left: 0 !important;
    }
  }
  .case {
    display: none;
  }
}
</style>